/*
 * @Author: 992526373@qq.com
 * @Date: 2022-08-18 11:56:55
 * @LastEditTime: 2022-08-22 17:40:53
 * @Description:
 */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Routes, Route, BrowserRouter, useLocation, Navigate, HashRouter } from 'react-router-dom';
import React, { Suspense, lazy, FC, ReactElement } from 'react';
import Login from '../Login';
import { AnimatePresence } from 'framer-motion';
import { UserInfoApi } from '../request/api';
import { IRes } from '../../typings/interface';

const App = lazy(() => import(/* webpackChunkName: "app" */ '../App'));
const Register = lazy(() => import(/* webpackChunkName: "register" */ '../Register'));
const LibraryList = lazy(() => import(/* webpackChunkName: "library" */ '../pages/LibraryList/LibraryList'));
const CourseList = lazy(() => import(/* webpackChunkName: "course" */ '../pages/CourseList/CourseList'));
const CourseEdit = lazy(() => import(/* webpackChunkName:"courseEdit" */ '../pages/CourseEdit/CourseEdit'));
const UserList = lazy(() => import(/* webpackChunkName: "user" */ '../pages/UserList/UserList'));
const ContactList = lazy(() => import(/* webpackChunkName:contact */ '../pages/ContactList/ContactList'));
const EpsApplyList = lazy(() => import(/* webpackChunkName: epsapply */ '../pages/EpsApplyList/EpsApplyList'));
const LibraryManage = lazy(() => import(/* webpackChunkName: libraryManage */ '../pages/LibraryManage/LibraryManage'));

interface Iroute {
    path: string;
    component: FC;
    children?: Iroute[];
}

const routeArr: Iroute[] = [
    {
        path: '/',
        component: App,
        children: [
            { path: 'librarylist', component: LibraryList },
            { path: 'userlist', component: UserList },
            { path: 'courselist', component: CourseList },
            { path: 'courseedit', component: CourseEdit },
            { path: 'courseedit/*', component: CourseEdit },
            { path: 'contactlist', component: ContactList },
            { path: 'epsapplylist', component: EpsApplyList },
            { path: 'librarymanage', component: LibraryManage },
        ],
    },
];

const AnimateRoutes = () => {
    const location = useLocation();

    UserInfoApi().then((res: IRes) => {
        if (res.errCode == 0) {
            localStorage.setItem('username', res.data.username);
            localStorage.setItem('tk-auth-token', res.data['tk-auth-token']);
            localStorage.setItem('avatar', res.data.avatar);
            localStorage.setItem('logintype', 'true');
        } else {
            localStorage.setItem('username', null);
            localStorage.setItem('tk-auth-token', null);
            localStorage.setItem('avatar', null);
            localStorage.setItem('logintype', 'false');
        }
    });

    // console.log(result);
    // console.log(localStorage.getItem('logintype'));

    return (
        <AnimatePresence exitBeforeEnter>
            <Routes location={location} key={location.pathname}>
                <Route path="/login" element={<Login />}></Route>
                <Route path="/register" element={<Register />}></Route>
                {/* <Route path="/" element={<App />}>
                    <Route path="librarylist" element={<LibraryList />} />
                    <Route path="userlist" element={<UserList />} />
                    <Route path="courselist" element={<CourseList />} />
                    <Route path="courseedit/*" element={<CourseEdit />} />
                    <Route path="contactlist" element={<ContactList />} />
                    <Route path="epsapplylist" element={<EpsApplyList />} />
                </Route>  */}
                {localStorage.getItem('logintype') == 'true'
                    ? routeArr.map((item, index) => {
                          return item.children ? (
                              <Route key={index} path={item.path} element={<item.component />}>
                                  {item.children.map((e, i) => (
                                      <Route key={`${index}-${i}`} path={e.path} element={<e.component />}></Route>
                                  ))}
                              </Route>
                          ) : (
                              <Route key={index} path={item.path} element={<item.component />}></Route>
                          );
                      })
                    : routeArr.map((item, index) => {
                          return item.children ? (
                              <Route key={index} path={item.path} element={<Navigate to="/login" />}>
                                  {item.children.map((e, i) => (
                                      <Route
                                          key={`${index}-${i}`}
                                          path={e.path}
                                          element={<Navigate to="/login" />}
                                      ></Route>
                                  ))}
                              </Route>
                          ) : (
                              <Route key={index} path={item.path} element={<Navigate to="/login" />}></Route>
                          );
                      })}
            </Routes>
        </AnimatePresence>
    );
};

const MyRouter: FC = (): ReactElement => {
    return (
        <HashRouter>
            <Suspense fallback={<div>loading...</div>}>
                <AnimateRoutes />
            </Suspense>
        </HashRouter>
    );
};

export default MyRouter;
